import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useContext } from 'react';
import { Flex } from 'rebass/styled-components';

import ShIcon from '../../../shared/components/sh-icon/sh-icon';
import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import useIsMobile from '../../../shared/hooks/is-mobile/useIsMobile';
import { IconPack } from '../../../shared/models/app-data-model';
import './embedded-search-field.scss';

export type EmbeddedSearchFieldProps = {
  iconPack: IconPack[];
};

const EmbeddedSearchField: React.FC<EmbeddedSearchFieldProps> = ({ iconPack }: EmbeddedSearchFieldProps) => {
  const uiStateContext = useContext(UiStateContext);
  const isMobile = useIsMobile();
  const [initialSearch, setInitialSearch] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (initialSearch && !uiStateContext.searchOpen) {
      uiStateContext.setSearchQuery?.('Sports Medicine');
    }
  });

  useLayoutEffect(() => {
    if (searchTerm?.length > 2 && !uiStateContext.searchOpen) {
      const delayDebounceFn = setTimeout(() => {
        setInitialSearch(false);
        uiStateContext.setSearchQuery?.(searchTerm);
      }, 500);

      /* istanbul ignore next */
      return () => clearTimeout(delayDebounceFn);
    }
  });

  useEffect(() => {
    if (!initialSearch) {
      setSearchTerm(uiStateContext.searchQuery);
    }
  }, [uiStateContext.searchQuery, initialSearch]);

  return (
    <Flex alignItems="center" className="embedded-search-bar" mx="auto">
      <Flex
        className="embedded-search-input-container"
        data-testid="embedded-search-input"
        alignItems="center"
        flexGrow="1"
        px={[4, 4, 4, 4, 6]}
      >
        <ShIcon iconPack={iconPack} iconName="search" className="search-icon icon" />
        <input
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          placeholder={isMobile ? 'Search Sports Medicine doctors' : 'Search for a Sports Medicine doctor'}
        />
      </Flex>
    </Flex>
  );
};

export default EmbeddedSearchField;
