/* eslint-disable max-len */

/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { heroType } from "../constants/constants";
import GlobalFlatFooter from "../global-flat-footer/global-flat-footer";
import GlobalFooter from "../global-footer/global-footer";
import EmbeddedSearch from "../search/embedded-search/embedded-search";
import EmbeddedScript from "../shared/components/embedded-script/embedded-script";
import HeroWrapper from "../shared/components/hero-wrapper/hero-wrapper";
import SEO from "../shared/components/seo/seo";
import { AppDataContext } from "../shared/contexts/app-data/AppDataContext";
import { UiStateContext } from "../shared/contexts/ui-state/UiStateContext";
import useComponentAlerts from "../shared/hooks/component-alerts/useComponentAlerts";
import usePageHasScrolled from "../shared/hooks/page-has-scrolled/usePageHasScrolled";
import { DeviceType } from "../shared/hooks/use-breakpoint/device-type";
import useBreakpoint from "../shared/hooks/use-breakpoint/useBreakpoint";
import useFeatureToggle from "../shared/hooks/use-feature-toggle/useFeatureToggle";
import {
  FeatureToggle,
  GlobalNavContent,
  HighImpactContainerHeroContent,
  IconPack,
  PageContent,
  PrimaryHeaderContent,
  SubsiteContent,
  GlobalToolbarContent,
  GlobalFooterContent,
  GlobalSettings,
  RegionalLinksModalContent,
  GlobalFlatFooterContent
} from "../shared/models/app-data-model";
import StickyHeader from "../sticky-header/sticky-header";
import PageModularBlock from "./page-modular-block/page-modular-block";
import "./page.scss";

export type PageProps = {
  content: PageContent;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  siteName: string;
  siteUrl: string;
  globalSettings: GlobalSettings;
  globalNavData: GlobalNavContent;
  primaryHeader: PrimaryHeaderContent;
  globalFooterData?: GlobalFooterContent;
  facebookDomainVerification?: string;
  twitterCreator?: string;
  twitterSite?: string;
  globalToolbarData?: GlobalToolbarContent;
  regionalLinksModalData?: RegionalLinksModalContent;
  corewellStyleOverrides?: boolean;
  globalFlatFooterContent?: GlobalFlatFooterContent;
  clearStepWidget?: string;
  clearStepResizeScript?: string;
};
/**
 * This Component renders all of our pages (besides the homepage)
 * We pull in all the page data from graphql and individual page data gets injected as props here
 *
 * @param {PageProps} { content }
 * @returns
 */
const Page: React.FC<PageProps> = ({
  content,
  featureToggles,
  iconPack,
  globalNavData,
  primaryHeader,
  globalFooterData,
  globalFlatFooterContent,
  siteName,
  siteUrl,
  facebookDomainVerification,
  twitterCreator,
  twitterSite,
  globalToolbarData,
  globalSettings,
  regionalLinksModalData,
  corewellStyleOverrides,
  clearStepWidget,
  clearStepResizeScript
}: PageProps) => {
  const appDataContext = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const [menusOpen, setMenusOpen] = useState(
    uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen || uiStateContext.megaMenuOpen
  );
  const pageRef = useRef<HTMLDivElement>(null);
  const deviceType = useBreakpoint();
  const fullBleedHeroPresent =
    content.hero?.[0]?.internal?.type === heroType.high || content.hero?.[0]?.internal?.type === heroType.reducedHeight;
  const useGlobalFlatFooter = useFeatureToggle("global-flat-footer", featureToggles);
  const conversationAgentEnabled = useFeatureToggle("clearstep-conversation-agent", featureToggles);

  usePageHasScrolled(pageRef, featureToggles);
  useComponentAlerts(content.alerts || [], "page");

  let title = siteName;

  if (content?.browser_title) {
    title = content.browser_title.includes(title) ? content.browser_title : content.browser_title + " | " + title;
  } else if (content?.seo?.title) {
    title = content.seo.title.includes(title) ? content.seo.title : content.seo.title + " | " + title;
  }

  // istanbul ignore else
  if (typeof document !== "undefined") {
    document.title = title;
  }

  /**
   *  On page mount, we need to make sure the megaMenuOpen is set to false, or else the conversation agent
   *  won't appear. When a <ShLink /> is clicked inside the mega menu, it doesn't set it to false...
   */
  useEffect(() => {
    uiStateContext?.setMegaMenuOpen?.(false);
  }, []);

  /**
   *  When a page opens, close the global search if it was open
   */
  useEffect(() => {
    uiStateContext.setSearchOpen?.(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiStateContext.setSearchOpen]);

  /**
   *  When search isn't open, be sure to clear the search query for next usage of search
   */
  useEffect(() => {
    if (!uiStateContext.searchOpen) {
      uiStateContext.setSearchQuery?.("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiStateContext.searchOpen, uiStateContext.setSearchQuery]);

  // if we're on the sports-medicine page, let's try to render embedded search.
  // it will only render if the toggle is also enabled, and we're on that page.
  // TODO: remove this check if/when we implement this feature fully and make it dynamic so it could be on any page, OR we remove the feature.
  const [attemptEmbeddedSearch] = useState(content?.url && content.url === "/services/orthopedics/sports-medicine-program");

  /**
   * this hook sets the subsite data when we land on a page
   * */
  useEffect(() => {
    appDataContext.setSubsiteData?.(content.subsite?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setSubsiteData, content.subsite]);

  /**
   * this hook sets the regional links modal data when we land on a page
   * */
  useEffect(() => {
    appDataContext.setRegionalLinksModalData?.(regionalLinksModalData as RegionalLinksModalContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setRegionalLinksModalData]);

  /**
   * This effect checks if our menus are open and sets our menusOpen bool
   */
  useEffect(() => {
    setMenusOpen(uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen || uiStateContext.megaMenuOpen);
  }, [setMenusOpen, uiStateContext.menuOpen, uiStateContext.auxNavOpen, uiStateContext.subsiteMenuOpen, uiStateContext.megaMenuOpen]);

  const seo = content.seo && (
    <div data-testid={"seo"}>
      <SEO
        siteName={siteName}
        // TODO could switch site url based on active ENV
        siteUrl={siteUrl}
        title={content.seo.title || title}
        facebookDomainVerification={facebookDomainVerification}
        twitterCreator={twitterCreator}
        twitterSite={twitterSite}
        defaultSiteImage={appDataContext?.appData?.primaryHeaderContent?.logo?.url}
        description={content.seo.description || siteName}
        pagePath={content.url}
        image={content.seo.image?.url}
        keywords={content.seo.keywords || ""}
        noIndex={!content.seo.show_in_search_engine_results}
        schema={`{
                "@type": "WebPage",
                "mainEntity": {
                  "@type": "Organization",
                  "name": "${siteName}",
                  "image": "${content.seo.image?.url}"
                }
              }`}
      />
    </div>
  );

  const embedScripts = appDataContext.embeddedScripts ? (
    <EmbeddedScript embedScriptBlocks={appDataContext.embeddedScripts} featureToggles={featureToggles} pagePath={content.url} />
  ) : null;

  const modularBlocks = content.modular_blocks
    ? content.modular_blocks.map((item, index) => (
        <PageModularBlock
          iconPack={iconPack}
          blockContent={item}
          key={index}
          featureToggles={featureToggles}
          heroType={content?.hero?.[0]?.internal?.type}
          campaignPageActive={content?.campaign_page}
          corewellStyleOverrides={corewellStyleOverrides}
        />
      ))
    : null;

  const hero = content?.hero && (
    <HeroWrapper
      content={content.hero}
      iconPack={iconPack}
      subsiteNavPresent={content?.subsite?.[0] ? true : false}
      headerPresent={true}
      breadcrumbs={{
        title: content.display_title ? content.display_title : content.title,
        subsite: content.subsite && (content.subsite[0] as unknown as SubsiteContent),
        hide_breadcrumbs: content?.hide_breadcrumbs
      }}
      featureToggles={featureToggles}
      corewellStyleOverrides={corewellStyleOverrides}
    />
  );

  // NOTE: the argument signature of <EmbeddedSearch> is a hint at what our content model should be if we implement this fully-dynamic.
  // specialtyName (used for many things, but also the search placeholder) and a list of specialty ids? ez. pz.
  const embeddedSearch = attemptEmbeddedSearch && (
    <EmbeddedSearch
      specialty={{ specialtyName: "Sports Medicine", specialtyIds: "5338,2576,2447" }}
      featureToggles={featureToggles}
      iconPack={iconPack}
      embeddedSearchHeading="Meet Our Physicians and Advanced Practice Providers"
    />
  );

  return (
    <>
      <StickyHeader
        featureToggles={featureToggles}
        globalNavContent={globalNavData}
        primaryHeaderContent={primaryHeader}
        dynamicPrimaryHeader={fullBleedHeroPresent}
        staticIcons={iconPack}
        subsiteData={content?.subsite?.[0]}
        campaignPageActive={content?.campaign_page}
        lightFontMode={
          !uiStateContext.pageHasScrolled &&
          fullBleedHeroPresent &&
          (content.hero?.[0] as unknown as HighImpactContainerHeroContent)?.overlay_text_color?.color === "light"
        }
        title={appDataContext?.appData?.globalSettings?.title}
        globalToolbarContent={globalToolbarData}
        corewellStyleOverrides={corewellStyleOverrides}
      />
      {conversationAgentEnabled &&
        clearStepWidget &&
        clearStepResizeScript &&
        !uiStateContext?.megaMenuOpen &&
        !uiStateContext.menuOpen && (
          <>
            <Helmet>
              <script type="text/javascript" src={clearStepResizeScript}></script>
            </Helmet>
            <iframe
              id="widget-iframe"
              src={clearStepWidget}
              title="Clearstep Symptom Check Widget"
              style={{ width: 0, height: 0 }}
            ></iframe>
          </>
        )}
      {/* todo: remove the hidden below when we can fix the modal/menu overflow scroll issue, it would be better not to have this */}
      <div hidden={deviceType < DeviceType.Desktop && menusOpen}>
        {seo}
        {embedScripts}
        <div ref={pageRef} data-testid="page" className={`page ${content?.campaign_page ? "campaign-page" : ""}`} id="page-content">
          <div>{hero}</div>
          {embeddedSearch}
          <div className="modular-block-container">{modularBlocks}</div>
        </div>
        {useGlobalFlatFooter ? (
          <GlobalFlatFooter
            content={globalFlatFooterContent as GlobalFlatFooterContent}
            title={globalSettings.title}
            copyrightSubtext={globalSettings.copyright_subtext}
            iconPack={iconPack}
          />
        ) : (
          <GlobalFooter
            iconPack={iconPack}
            content={globalFooterData as GlobalFooterContent}
            copyrightSubtext={globalSettings.copyright_subtext}
            title={globalSettings.title}
          />
        )}
      </div>
    </>
  );
};

export default Page;
