import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { layout } from '../../../constants/constants';
import Divider from '../../../shared/components/divider/divider';
import ErrorBanner from '../../../shared/components/error-banner/error-banner';
import LoadingSpinner from '../../../shared/components/loading-spinner/loading-spinner';
import ProviderCardDesktop from '../../../shared/components/provider-card-desktop/provider-card-desktop';
import { mapKendraProvider } from '../../../shared/components/provider-card-grid/provider-card-grid';
import ProviderCardMobile from '../../../shared/components/provider-card-mobile/provider-card-mobile';
import { Media } from '../../../shared/contexts/media/MediaContext';
import { UiStateContext } from '../../../shared/contexts/ui-state/UiStateContext';
import useContentStack from '../../../shared/hooks/contentstack-api/useContentStack';
import useKendraApi from '../../../shared/hooks/kendra-api/useKendraApi';
import useSortProviderResults, {
  SearchProviderFilters,
  SearchProviderSort
} from '../../../shared/hooks/search-providers-sort/useSortProviderResults';
import { DeviceType } from '../../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../../shared/hooks/use-breakpoint/useBreakpoint';
import usePagination from '../../../shared/hooks/use-pagination/usePagination';
import { FeatureToggle, GlobalSearchNoResultsContent, IconPack, ProviderSpecialty } from '../../../shared/models/app-data-model';
import { Location, Page, Provider } from '../../../shared/services/kendra-api/kendra-api-service';
import NoResults from '../../no-results/no-results';
import Paginator from '../../paginator/paginator';
import ProviderFilter from '../../providers/provider-filter/provider-filter';
import SearchClearFilters from '../../search-clear-filters/search-clear-filters';
import SearchMobileHeader from '../../search-mobile-header/search-mobile-header';
import SearchTotalResults from '../../search-total-results/search-total-results';
import './embedded-search-result.scss';

export type EmbeddedSearchResultProps = {
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  specialty: ProviderSpecialty;
};

const EmbeddedSearchResult: React.FC<EmbeddedSearchResultProps> = ({ iconPack, featureToggles, specialty }: EmbeddedSearchResultProps) => {
  const uiStateContext = useContext(UiStateContext);
  const [kendraApi, retry] = useKendraApi({
    providerSpecialtyIds: specialty.specialtyIds,
    shEmployedOnly: true,
    enableQuery: uiStateContext.embeddedSearchShown && !uiStateContext.searchOpen
  });
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showClearFilters, setShowClearFilter] = useState(false);
  const resultsRef = useRef<HTMLDivElement | null>(null);

  // Search logic: Uses global search context, shares with the header search
  const [searchResultData, setSearchResultData] = useState(new Array<Provider | Location | Page>());
  const filteredResults = useSortProviderResults(searchResultData as Provider[]);
  const [searchSuggestions] = useState(new Array<string>());
  const [currentPageData, paginationDetails, setCurrentPage] = usePagination(filteredResults, 5);
  const [initialState, setInitialState] = useState(true);

  useEffect(() => {
    /* istanbul ignore else */
    if (kendraApi?.response && kendraApi.response?.query_result) {
      setSearchResultData(kendraApi.response.query_result);
      setInitialState(false);
    }
  }, [kendraApi]);

  const handleFilterSelectChange = useCallback(
    (newFilter?: SearchProviderFilters): void => {
      uiStateContext.setProviderFilters(newFilter);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleSortSelectChange = useCallback(
    (newSort?: SearchProviderSort): void => {
      uiStateContext.setProviderSort(newSort);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleClearFilter = useCallback((): void => {
    uiStateContext.setProviderFilters(undefined);
    uiStateContext.setProviderSort(undefined);
    setCurrentPage(1);
  }, [uiStateContext, setCurrentPage]);

  useEffect(() => {
    setShowClearFilter(
      Object.values(uiStateContext.providerFilters || {}).some((x) => x !== undefined) ||
        Object.values(uiStateContext.providerSort || {}).some((x) => x !== undefined)
    );
  }, [uiStateContext.providerFilters, uiStateContext.providerSort]);

  // Pagination
  const onPageChange = useCallback(
    (currentPage: number): void => {
      setCurrentPage(currentPage);
      const resultsTopOffset = resultsRef.current?.getBoundingClientRect().top;
      if (resultsTopOffset) {
        window.scrollTo({
          top: resultsTopOffset + window.scrollY - 125,
          behavior: 'smooth'
        });
      }
    },
    [setCurrentPage]
  );

  const noResultsFoundContent = useContentStack([
    {
      contentType: 'global_search_no_results_spectrumhealth_org',
      entryUid: 'bltf982f7c25b9da053',
      referencedFields: ['modular_blocks.content_card_grid.cards.content_card.reference']
    }
  ])?.responses[0]?.content as GlobalSearchNoResultsContent;

  const noResultsState = (
    <NoResults content={noResultsFoundContent} searchSuggestions={searchSuggestions} featureToggles={featureToggles} />
  );

  const loadingState = <LoadingSpinner />;

  const errorState = (
    <Box width={layout.pageWidth} pr={layout.searchPaddingX} margin="0">
      <ErrorBanner
        subMessage={
          'An error occurred while trying to connect you to your search results. You may enter a new search query, or click "Try Again" to retry your search.'
        }
        onReload={retry}
      />
    </Box>
  );

  // Breakpoint for using mobile providers cards, need room for filters & results to display nicely
  const deviceType = useBreakpoint(640, 1160);
  const searchResultsView = (): any => {
    // todo: refactor to display result cards based on the shape of the data so we can properly display locations as well

    const searchResults =
      currentPageData.length > 0 ? (
        <div data-testid="search-results-view">
          <SearchTotalResults count={searchResultData.length} />
          <Flex flexDirection="column" width={1}>
            {(currentPageData as Provider[]).map((item) => (
              <Box key={item.provider_id} width={1} mx="auto">
                {deviceType === DeviceType.Desktop ? (
                  <ProviderCardDesktop iconPack={iconPack} key={`provider-card-desktop-${item.provider_id}`} provider={item as Provider} />
                ) : (
                  <ProviderCardMobile
                    iconPack={iconPack}
                    key={`provider-card-mobile-${item.provider_id}`}
                    provider={mapKendraProvider(item as Provider)}
                  />
                )}
              </Box>
            ))}
          </Flex>
          <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
        </div>
      ) : (
        noResultsState
      );

    const searchFilter = (
      <>
        <Media lessThan="desktop">
          <Flex flexDirection="column" width={1} className="mobile-view-container">
            <SearchMobileHeader
              showMobileFilters={showMobileFilters}
              setShowMobileFilters={setShowMobileFilters}
              showClearFilters={showClearFilters}
              clearFilter={handleClearFilter}
              iconPack={iconPack}
            />
            {showMobileFilters ? (
              <Box marginBottom="50px" className="embedded-search-mobile-filters">
                <ProviderFilter
                  onSortSelectChange={handleSortSelectChange}
                  onFilterSelectChange={handleFilterSelectChange}
                  embeddedSearch={specialty?.specialtyName}
                />
              </Box>
            ) : (
              <div></div>
            )}
          </Flex>
        </Media>
        <Media greaterThanOrEqual="desktop">
          <Flex flexDirection="column" alignItems="flex-start" className="search-filters-wrapper" width={1}>
            <Box className="search-filter-option" py={2}>
              <span className="body-small">Refine your search</span>
            </Box>
            <ProviderFilter
              onSortSelectChange={handleSortSelectChange}
              onFilterSelectChange={handleFilterSelectChange}
              embeddedSearch={specialty?.specialtyName}
            />
            {showClearFilters && (
              <>
                <Divider className="embedded-search-divider" />
                <SearchClearFilters iconPack={iconPack} clearFilter={handleClearFilter} />
              </>
            )}
          </Flex>
        </Media>
      </>
    );

    return (
      <Flex className="embedded-search-results-container" mx={[0, 0, 0, -2, -2, -3]} flexDirection="row-reverse" flexWrap="wrap" width={1}>
        <Box width={[1, 1, 1, 1, 1 / 3, 1 / 4]} px={[0, 0, 0, 2, 2, 3]}>
          {searchFilter}
        </Box>
        <Box width={[1, 1, 1, 1, 2 / 3, 3 / 4]} px={[0, 0, 0, 2, 2, 3]} pb={[4, 4, 4, 0]}>
          {searchResults}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex className="embedded-search-results" pt={[2, 2, 4, 5]} px={[4, 4, 4, 4, 6]} ref={resultsRef}>
      {kendraApi.hasError
        ? errorState
        : (kendraApi.isRunning && uiStateContext.searchQuery?.length > 2) || initialState
        ? loadingState
        : uiStateContext.searchQuery?.length === 0
        ? noResultsState
        : searchResultsView()}
    </Flex>
  );
};

export default EmbeddedSearchResult;
