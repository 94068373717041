import React, { useContext, useEffect } from 'react';
import { Box } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import { UiStateContext } from '../../shared/contexts/ui-state/UiStateContext';
import useFeatureToggle from '../../shared/hooks/use-feature-toggle/useFeatureToggle';
import { FeatureToggle, IconPack, ProviderSpecialty } from '../../shared/models/app-data-model';
import EmbeddedSearchField from './embedded-search-field/embedded-search-field';
import EmbeddedSearchResult from './embedded-search-result/embedded-search-result';
import './embedded-search.scss';

export type EmbeddedSearchProps = {
  specialty: ProviderSpecialty;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  embeddedSearchHeading: string;
};

const EmbeddedSearch: React.FC<EmbeddedSearchProps> = ({
  specialty,
  featureToggles,
  iconPack,
  embeddedSearchHeading
}: EmbeddedSearchProps) => {
  const uiStateContext = useContext(UiStateContext);
  const showEmbeddedSearch: boolean = useFeatureToggle('embedded-search', featureToggles);

  /**
   *  Tell everyone else when embeddedSearch is shown to user
   */
  useEffect(() => {
    uiStateContext.setEmbeddedSearchShown?.(showEmbeddedSearch);
  }, [showEmbeddedSearch, uiStateContext.setEmbeddedSearchShown]);

  return showEmbeddedSearch ? (
    <Box pt={5} pb={6} my={[0, 0, 2, 4, 4, 4, 10]} data-testid="embedded-search-root">
      <Box className="embedded-search-heading" marginX={'auto'} width={layout.pageWidth}>
        <h2>{embeddedSearchHeading}</h2>
      </Box>
      <Box className="embedded-search" width={layout.pageWidth} mx="auto">
        <div className="embedded-search-field-container">
          <EmbeddedSearchField iconPack={iconPack} />
        </div>
        <EmbeddedSearchResult iconPack={iconPack} featureToggles={featureToggles} specialty={specialty} />
      </Box>
    </Box>
  ) : null;
};

export default EmbeddedSearch;
