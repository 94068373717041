import Page from '../../../design-system/page/page';
import { AppDataContext } from '../../../design-system/shared/contexts/app-data/AppDataContext';
import {
  AppDataModel,
  GlobalAlertsContent,
  GlobalFlatFooterContent,
  GlobalFooterContent,
  GlobalNavContent,
  GlobalSettings,
  GlobalToolbarContent,
  IconPack,
  PageContent,
  PrimaryHeaderContent,
  RegionalLinksModalContent
} from '../../../design-system/shared/models/app-data-model';
import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import globalSettings from "../../global-settings.json";


export type ContentStackPageProps = {
  data: ContentStackPageQueryResponse;
};

export type ContentStackPageQueryResponse = {
  shPages: PageContent;
  shSpectrumhealthOrgPrimaryHeader: PrimaryHeaderContent;
  shGlobalHeaderNav: GlobalNavContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shGlobalAlertsSpectrumhealthOrg: GlobalAlertsContent;
  shSpectrumhealthOrgGlobalFooterNav: GlobalFooterContent;
  shGlobalToolbar: GlobalToolbarContent;
  shRegionalLinksModal?: RegionalLinksModalContent;
  shGlobalFlatFooter?: GlobalFlatFooterContent;
};

const ContentStackPage: React.FC<ContentStackPageProps> = (props: ContentStackPageProps) => {
  const context = useContext(AppDataContext);
  const clearStepWidget = globalSettings?.entry?.clearstep_widget_page_source;
  const clearStepResizeScript = globalSettings?.entry?.clearstep_resize_script_source;

  useEffect(() => {
    if (context.setAppData) {
      const appData: AppDataModel = {
        ...context.appData
      };

      context.setAppData(appData);
    }
    // we can't depend on context or we endless loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      content={props.data.shPages}
      iconPack={props.data.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[]}
      featureToggles={props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles}
      siteName={props.data.shSpectrumhealthOrgGlobalSettings.title}
      siteUrl={props.data.shSpectrumhealthOrgGlobalSettings.prod_url}
      globalSettings={props?.data?.shSpectrumhealthOrgGlobalSettings}
      globalNavData={props?.data?.shGlobalHeaderNav}
      primaryHeader={props?.data?.shSpectrumhealthOrgPrimaryHeader}
      globalFooterData={props?.data?.shSpectrumhealthOrgGlobalFooterNav}
      globalFlatFooterContent={props?.data?.shGlobalFlatFooter}
      facebookDomainVerification={props.data.shSpectrumhealthOrgGlobalSettings.facebook_domain_verification}
      twitterCreator={props.data.shSpectrumhealthOrgGlobalSettings.twitter_creator}
      twitterSite={props.data.shSpectrumhealthOrgGlobalSettings.twitter_site}
      globalToolbarData={props?.data?.shGlobalToolbar}
      regionalLinksModalData={props?.data?.shRegionalLinksModal}
      corewellStyleOverrides={true}
      clearStepWidget={clearStepWidget}
      clearStepResizeScript={clearStepResizeScript}
    />
  );
};

export const ContentStackPageQuery = graphql`
  query ($id: String!) {
    shPages(id: { eq: $id }) {
      ...pageFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shGlobalHeaderNav {
      ...globalNavFragment
    }
    shMegaMenu {
      ...megaMenuFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
    shGlobalToolbar {
      ...globalToolbarFragment
    }
    shRegionalLinksModal {
      ...regionalLinksModalFragment
    }
    shGlobalFlatFooter {
      ...shGlobalFlatFooterFragment
    }
  }
`;

export default ContentStackPage;
